<template>
  <div class="editable-form">
    <div
      v-for="field in fields"
      :key="field.key"
      :class="[
        ['textarea'].includes(field.type)
          ? 'editable-form__row'
          : 'editable-form__column',
      ]"
    >
      <slot
        :name="field.key"
        :field="field"
      >
        <p
          v-show="!['checkbox', 'file'].includes(field.type)"
          class="editable-form__description"
        >
          {{ field.label }}
        </p>
        <component
          v-model="values[field.key]"
          v-bind="{
        options: field.list,
        ...fieldTypes[field.type].attributes,
        ...field.attributes,
        label: field.type === 'checkbox' ? field.label : '',
        value: field.type === 'file' ? values[field.key] : null,
        class: fieldTypes[field.type].classes,
      }"
          :is="fieldTypes[field.type].component"
          :disabled="field.disabled || disabledAllFields"
          @input="(value) => onInput(value, field.key)"
          @select="(option) => onSelect(option, field.key)"
          @changeFile="(file) => onChangeFile(file, field.key)"
          @downloadFile="onDownloadFile(field.key)"
          @removeFile="onRemoveFile(field.key)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import smInput from '@/components/common/forms/SmInput.vue';
import smNumericInput from '@/components/common/forms/SmNumericInput.vue';
import smTextarea from '@/components/common/forms/SmTextarea.vue';
import smCheckbox from '@/components/common/forms/SmCheckbox.vue';
import smFileinput from '@/components/common/forms/SmFileinput.vue';
import smSelect from '@/components/common/forms/SmSelect.vue';
import smDatepicker from '@/components/common/forms/SmDatepicker.vue';

export default {
  name: 'SmEditableForm',

  components: {
    smInput,
    smNumericInput,
    smTextarea,
    smCheckbox,
    smFileinput,
    smSelect,
    smDatepicker,
  },

  model: {
    prop: 'values',
  },

  props: {
    /**
     * Массив параметров для генерации
     * [{
     *  type: 'text| html | email | password | integerNumber | fileinput | decimalNumber | select | checkbox | date | datetime | file',
     *  label: '',
     *  placeholder: '',
     *  key: '',
     *  list: ''
     * }]
     */

    fields: {
      type: Array,
      required: true,
    },

    values: {
      type: Object,
    },

    disabledAllFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fieldTypes: {
        text: {
          component: 'sm-input',
          attributes: {
            type: 'text',
          },
        },

        number: {
          component: 'sm-input',
          attributes: {
            type: 'number',
            step: 1,
          },
          classes: [],
        },

        integerNumber: {
          component: 'sm-numeric-input',
          attributes: {
            step: 1,
          },
          classes: [],
        },

        decimalNumber: {
          component: 'sm-numeric-input',
          attributes: {
            step: 0.1,
          },
          classes: [],
        },

        textarea: {
          component: 'sm-textarea',
          attributes: {
            rows: 5,
            maxRows: 5,
          },
          classes: [],
        },

        checkbox: {
          component: 'sm-checkbox',
          attributes: {},
          classes: ['editable-form__checkbox'],
        },

        file: {
          component: 'sm-fileinput',
          attributes: {},
          classes: [],
        },

        select: {
          component: 'sm-select',
          attributes: {
            search: true,
          },
          classes: [],
        },

        multiSelect: {
          component: 'sm-select',
          attributes: {
            search: true,
            multiselect: true,
            selectAll: true,
          },
          classes: [],
        },

        date: {
          component: 'sm-datepicker',
          attributes: {
            type: 'date',
          },
          classes: [],
        },

        time: {
          component: 'sm-datepicker',
          attributes: {
            type: 'time',
            format: 'HH:mm:ss',
            valueType: 'HH:mm:ss',
            timeTitleFormat: 'HH:mm:ss',
          },
          classes: [],
        },

        dateTime: {
          component: 'sm-datepicker',
          attributes: {
            type: 'datetime',
            format: 'DD.MM.YYYY HH:mm',
            valueType: 'DD.MM.YYYY HH:mm',
            timeTitleFormat: 'DD.MM.YYYY HH:mm',
          },
          classes: [],
        },

        fullDateTime: {
          component: 'sm-datepicker',
          attributes: {
            type: 'datetime',
            format: 'DD.MM.YYYY HH:mm:ss',
            valueType: 'DD.MM.YYYY HH:mm:ss',
            timeTitleFormat: 'DD.MM.YYYY HH:mm:ss',
          },
          classes: [],
        },
      },
    };
  },

  methods: {
    // Рефакторинг
    setFilename(file) {
      if (file && file.fileName) {
        return file.fileName;
      }
      return null;
    },

    onSelect(option, key) {
      this.$emit('select', { option, key });
    },

    onInput(value, key) {
      this.$emit('inputField', { key, value });
    },

    onChangeFile(file, key) {
      this.$emit('changeFile', { file, key });
    },

    onDownloadFile(key) {
      this.$emit('downloadFile', { key });
    },

    onRemoveFile(key) {
      this.$emit('removeFile', { key });
    },
  },
};
</script>

<style lang="scss">
.editable-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding-bottom: 15px;
  // column-gap: 15px;
  // columns: 2;
}

.editable-form__row {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  margin-bottom: 15px;
  padding: 0 15px;
}

.editable-form__column {
  display: flex;
  // flex: 0 0 50%;
  min-width: 50%;
  // align-self: center;
  margin-bottom: 15px;
  padding: 0 15px;
  // max-width: 50%;
  // break-inside: avoid;

  @media only screen and (min-width: var(--breakpoint-md-max)) {
    flex: 0 0 100%;
  }
}

.editable-form__description {
  align-self: center;
  width: 250px;

  line-height: 1;
}

.editable-form__row .editable-form__description {
  width: 100%;
}
</style>
