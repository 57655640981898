var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editable-form"},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key,class:[
      ['textarea'].includes(field.type)
        ? 'editable-form__row'
        : 'editable-form__column' ]},[_vm._t(field.key,function(){return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(!['checkbox', 'file'].includes(field.type)),expression:"!['checkbox', 'file'].includes(field.type)"}],staticClass:"editable-form__description"},[_vm._v(" "+_vm._s(field.label)+" ")]),_c(_vm.fieldTypes[field.type].component,_vm._b({tag:"component",attrs:{"disabled":field.disabled || _vm.disabledAllFields},on:{"input":function (value) { return _vm.onInput(value, field.key); },"select":function (option) { return _vm.onSelect(option, field.key); },"changeFile":function (file) { return _vm.onChangeFile(file, field.key); },"downloadFile":function($event){return _vm.onDownloadFile(field.key)},"removeFile":function($event){return _vm.onRemoveFile(field.key)}},model:{value:(_vm.values[field.key]),callback:function ($$v) {_vm.$set(_vm.values, field.key, $$v)},expression:"values[field.key]"}},'component',Object.assign({}, {options: field.list},
      _vm.fieldTypes[field.type].attributes,
      field.attributes,
      {label: field.type === 'checkbox' ? field.label : '',
      value: field.type === 'file' ? _vm.values[field.key] : null,
      class: _vm.fieldTypes[field.type].classes}),false))]},{"field":field})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }